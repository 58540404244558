import React from 'react';
import PropTypes from 'prop-types';

import styles from './AnchorLink.module.scss';

const AnchorLink = ({ title, id, className = '' }) =>
    id ? (
        <a className={`${className} Anchor`} href={`#${id}`} id={id}>
            <span>{title}</span>
        </a>
    ) : (
        <span>{title}</span>
    );

AnchorLink.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
};

export default AnchorLink;
