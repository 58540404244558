import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from '../BackgroundImage';
import VisibilitySensor from 'react-visibility-sensor';
import { useTranslation } from 'next-i18next';

import FadeIn from '../../transitions/FadeIn';

import { serializeImage } from '../../utils/SerializeImage';
import debounce from '../../utils/debounce';
import { breakpoints } from '../../constants';

import classNames from 'classnames';
import isEmpty from '../../utils/isEmpty';
import useMediaQuery from '../../utils/useMediaQuery';
import AnchorLink from '../AnchorLink';

import styles from './ArticleList.module.scss';

const useScrollPosition = (elem) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const listener = debounce(() => {
        setScrollPosition(elem.current.scrollLeft);
    }, 200);

    useEffect(() => {
        if (!elem.current) {
            return;
        }

        const el = elem.current;

        el.addEventListener('scroll', listener, { passive: true });
        return () => {
            el.removeEventListener('scroll', listener);
        };
    }, [elem, listener]);

    return {
        scrollPosition,
    };
};

const ArticleList = ({
    id,
    items,
    href,
    title,
    preamble,
    usp,
    landingType,
    isPremium,
    whereToGoType,
    isRootWhereToGo,
    isLeaf,
    anchorLink,
    // isLandingPage,
}) => {
    const { t } = useTranslation('common');
    const sliderElement = useRef(null);
    const { scrollPosition } = useScrollPosition(sliderElement);
    const isTablet = useMediaQuery({ query: `(max-width: ${breakpoints.l}px)` });

    const keys = [32, 13];

    const slide = (dir, e) => {
        e.preventDefault();

        if (e.keyCode && keys.indexOf(e.keyCode) === -1) {
            return;
        }

        if (!sliderElement.current) {
            return;
        }

        const scrollLength = sliderElement.current.clientWidth / 2;

        let scroll = {
            behavior: 'smooth',
        };

        if (dir === 'left') {
            scroll.left = scrollPosition - scrollLength;
        } else {
            scroll.left = scrollPosition + scrollLength;
        }

        sliderElement.current.scroll(scroll);
    };

    if (isEmpty(items)) {
        return null;
    }

    // const isSingle = items.length === 1;

    const slideRight = (e) => slide('right', e);
    const slideLeft = (e) => slide('left', e);

    const percentageScrolled = sliderElement.current
        ? (scrollPosition /
              (sliderElement.current.scrollWidth -
                  sliderElement.current.clientWidth)) *
          100
        : 0;
    const isAtRightEdge = percentageScrolled === 100;

    const getVisibleItemsCount = () => {
        if (isTablet) {
            return 2;
        } else {
            return 4;
        }
    };

    const visibleItemsCount = getVisibleItemsCount();
    const isScrollable = items.length > visibleItemsCount;

    const classes = classNames(styles['ArticleList'], {
        [styles['ArticleList--NonPremium']]: !isPremium,
        [styles['ArticleList--Premium']]: isPremium,
        // [styles["ArticleList--Single"]]: isSingle,
        [styles['ArticleList--Northern']]:
            isRootWhereToGo && whereToGoType == 'northern-sweden',
        [styles['ArticleList--Southern']]:
            isRootWhereToGo && whereToGoType == 'southern-sweden',
        [styles['ArticleList--Central']]:
            isRootWhereToGo && whereToGoType == 'middle-sweden',
        // [styles["ArticleList--Narrow"]]: items.length <= 3 && !isLandingPage,
        [styles['ArticleList--HasIndicator']]: items.length > 4,
    });

    const showViewAll = items.length > 4 && !isLeaf;

    const titleClasses = classNames(styles['ArticleList__Title'], {
        [styles['ArticleList__Title--Linked']]: href,
        [styles['ArticleList__Title--Arrow']]: showViewAll,
    });

    return (
        <div className={classes}>
            <div className={styles['ArticleList__Container']}>
                <div className={styles['ArticleList__TextContainer']}>
                    {href && (
                        <h2 className={titleClasses}>
                            <a href={href}>
                                <span className="sr-only">{title}</span>
                            </a>
                            <span>{title}</span>

                            {showViewAll && (
                                <span
                                    className={styles['ArticleList__ShowMore']}
                                    aria-hidden={true}>
                                    <span
                                        className={
                                            styles['ArticleList__ShowMoreText']
                                        }>
                                        {t('articleList.viewAll')}
                                    </span>
                                </span>
                            )}
                        </h2>
                    )}
                    {!href && (
                        <h2 className={styles['ArticleList__Title']}>
                            <AnchorLink title={title} id={anchorLink} />
                        </h2>
                    )}
                    {(preamble || usp) && (
                        <div className={styles['ArticleList__Preamble']}>
                            {usp || preamble}
                        </div>
                    )}

                    <div className={styles['ArticleList__Line']} />
                </div>

                <FadeIn visible={scrollPosition > 0 && isScrollable}>
                    <span>
                        <button
                            className={styles['ArticleList__ArrowLeft']}
                            type="button"
                            onClick={(e) => slideLeft(e)}>
                            <span className="sr-only">
                                {t('articleList.scrollLeft')}
                            </span>
                        </button>
                    </span>
                </FadeIn>

                <div className={styles['ArticleList__SlideWrap']}>
                    <div
                        className={styles['ArticleList__Slidable']}
                        tabIndex={-1}
                        ref={sliderElement}>
                        {items.map((item, i) => (
                            <ArticleListCard
                                {...item}
                                landingType={landingType}
                                landingId={id}
                                index={i}
                                key={i}
                            />
                        ))}
                    </div>
                </div>

                <FadeIn visible={!isAtRightEdge && isScrollable}>
                    <span>
                        <button
                            className={styles['ArticleList__ArrowRight']}
                            type="button"
                            onClick={(e) => slideRight(e)}>
                            <span className="sr-only">
                                {t('articleList.scrollRight')}
                            </span>
                        </button>
                    </span>
                </FadeIn>

                {items.length > 3 && (
                    <ArticleListPager
                        itemsCount={items.length}
                        percentageScrolled={percentageScrolled}
                        isScrollable={isScrollable}
                    />
                )}
            </div>
        </div>
    );
};

ArticleList.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    items: PropTypes.array,
    title: PropTypes.string,
    preamble: PropTypes.string,
    usp: PropTypes.string,
    href: PropTypes.string,
    anchorLink: PropTypes.string,
    isPremium: PropTypes.bool,
    isLandingPage: PropTypes.bool,
    isLeaf: PropTypes.bool,
    isRootWhereToGo: PropTypes.bool,
    whereToGoType: PropTypes.string,
    landingType: PropTypes.string,
};

ArticleList.defaultProps = {
    items: [],
    title: '',
    preamble: '',
    usp: '',
    href: '',
    anchorLink: '',
    isPremium: false,
    isLandingPage: false,
    isRootWhereToGo: false,
    isLeaf: false,
    landingType: '',
    whereToGoType: '',
};

const ArticleListCard = ({
    image,
    title,
    href,
    type,
    landingType,
    landingId,
    index,
}) => {
    const { t } = useTranslation('common');
    const [isVisible, setIsVisible] = useState(index === 0);

    image = serializeImage(image);

    const sizes = '(max-width: 768px) 100vw, (max-width: 1280px) 50vw, 375px';

    const classes = classNames(
        styles['ArticleList__Card'],
        styles[`ArticleList__Card--${type}`]
    );

    const tagMap = {
        external: t('articleList.tagExternal'),
        city: t('articleList.tagCity'),
        region: t('articleList.tagRegion'),
    };

    if (landingType === 'what-to-do' && landingId) {
        href = `${href}?what_to_do=${landingId}`;
    }

    return (
        <VisibilitySensor onChange={(x) => setIsVisible(index === 0 || x)}>
            <a href={href} className={classes} tabIndex={isVisible ? 0 : -1}>
                <div className={styles['ArticleList__Image']}>
                    <BackgroundImage
                        {...image}
                        quality={60}
                        sizes={sizes}
                        hideAlt={true}
                    />
                </div>
                <div className={styles['ArticleList__CardContent']}>
                    <h3 className={styles['ArticleList__CardTitle']}>
                        {type && (
                            <div
                                aria-hidden={true}
                                className={styles['ArticleList__Tag']}>
                                {tagMap[type]}
                            </div>
                        )}
                        {title}
                    </h3>
                </div>
            </a>
        </VisibilitySensor>
    );
};

ArticleListCard.propTypes = {
    image: PropTypes.object,
    title: PropTypes.string,
    href: PropTypes.string,
    type: PropTypes.string,
    landingType: PropTypes.string,
    landingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    index: PropTypes.number,
};

ArticleListCard.defaultProps = {
    image: {},
    href: '',
    title: '',
    type: '',
    landingType: '',
};

const ArticleListPager = ({ itemsCount, percentageScrolled, isScrollable }) => {
    const isResponsive = useMediaQuery({
        query: `(max-width: ${breakpoints.m}px)`,
    });

    if (!isResponsive && !isScrollable) {
        return null;
    }

    return (
        <div className={styles['ArticleList__Pager']}>
            <div className={styles['ArticleList__PagerBg']}>
                <div
                    className={styles['ArticleList__PagerContent']}
                    style={{ width: `${percentageScrolled}%` }}></div>
            </div>
        </div>
    );
};

const GeneralArticleList = ({ items, landingType }) => {
    const { t } = useTranslation('common');
    const title =
        landingType === 'where-to-go'
            ? t('articleList.generalWhereToGoTitle')
            : t('articleList.generalWhatToDoTitle');

    return <ArticleList isLandingPage={true} items={items} title={title} />;
};

const MoreInArticleList = ({ items, parentTitle }) => {
    const { t } = useTranslation('common');
    return (
        <ArticleList
            isLandingPage={true}
            items={items}
            title={t('articleList.moreIn', { parentTitle })}
        />
    );
};

ArticleListPager.propTypes = {
    itemsCount: PropTypes.number,
    percentageScrolled: PropTypes.number,
};

ArticleListPager.defaultProps = {
    itemsCount: 0,
    percentageScrolled: 0,
};

GeneralArticleList.propTypes = {
    items: PropTypes.array,
    landingType: PropTypes.string,
};
MoreInArticleList.propTypes = {
    items: PropTypes.array,
    landingType: PropTypes.string,
    parentTitle: PropTypes.string,
};

export { GeneralArticleList, MoreInArticleList };
export default ArticleList;
